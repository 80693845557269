import React from 'react';

import { Base, Container, Flex, Text, Clickable } from '../lib/atoms';

import { Linkedin } from '@styled-icons/boxicons-logos/Linkedin';
import { Github } from '@styled-icons/bootstrap/Github';

const YELLOW = '#FFB03A';

const BLOG_POSTS = [
    {
        title: 'How is Braid made',
        description: 'A little on how to make a game travel time like Braid did.',
        link: 'https://dev.to/lxchurbakov/how-is-braid-made-5ecj',
    },
    {   
        title: 'Create P2P network from scratch',
        description: 'Making of simple P2P network wtih Node',
        link: 'https://dev.to/lxchurbakov/create-a-p2p-network-with-node-from-scratch-1pah',
    },
    {   
        title: 'Create a Torrent app',
        description: 'Create a custom protocol torrent app with Noed',
        link: 'https://dev.to/lxchurbakov/create-a-torrent-application-with-node-from-scratch-1j3e',
    },
    {   
        title: 'Making computer with GoL',
        description: 'How to make logic circuit with Game of Life',
        link: 'https://dev.to/lxchurbakov/how-to-make-a-logic-circuit-with-conways-game-of-life-1f54',
    },
];

export default () => {
    const links = [
        { icon: Github, href: '//linkedin.com/in/lxch' },
        { icon: Linkedin, href: '//github.com/lxchurbakov' },
    ];

    return (
        <Container pt="20px">
            <Text mb="128px" color={YELLOW} size="18px" weight="500">~ lxch.io</Text>

            <Text mb="32px" color={YELLOW} mw="600px" size="18px" weight="500">Alexander Churbakov</Text>
            <Text line="1.4" mb="32px" mw="500px" size="18px" weight="300">Fullstack JS/TS developer with a decade of professional experience.</Text>

            <Flex mb="128px" justify="start" gap="12px">
                {links.map(({ icon: Icon, href }) => (
                    <Clickable onClick={() => window.open(href, '_blank')}>
                        <Icon color={YELLOW} size={28} />
                    </Clickable>
                ))}
            </Flex>

            <Base>
                <Text mb="32px" size="18px" color={YELLOW}>Blog posts</Text>
                
                <Flex isWrap justify="flex-start" gap="48px">
                    {BLOG_POSTS.map((post, index) => (
                        <Base key={index} mw="350px">
                            <Text mb="24px" size="18px" weight="500">{post.title}</Text>
                            <Text mb="24px" size="18px">{post.description}</Text>

                            <Clickable>
                                <Text size="18px" color={YELLOW} weight="500">
                                    <a target="_blank" href={post.link}>{'>'} Read more</a>
                                </Text>
                            </Clickable>
                        </Base>
                    ))}
                </Flex>
            </Base>
        </Container>
    );
};
